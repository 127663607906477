// src/components/Header/Header.jsx
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

// Example Icons (React Icons)
import {
  FaHome,
  FaUsers,
  FaNewspaper,
  FaCalendarAlt,
  FaHandsHelping,
  FaHandHoldingHeart,
  FaFacebook,
  FaInstagram,
  FaBookOpen,
} from 'react-icons/fa';
import { MdContactPhone } from 'react-icons/md';

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => setMenuOpen(!menuOpen);
  const closeMenu = () => setMenuOpen(false);

  return (
      <header className="main-header">
        {/* Left: Logo + Nav */}
        <div className="left-section">
          <Link to="/" onClick={closeMenu}>
            <img
                src="/images/vFoundationLogo.png"
                alt="Venecia's Foundation Logo"
                className="logo"
            />
          </Link>

          <nav className={`nav-links ${menuOpen ? 'open' : ''}`}>
            <ul>
              <li>
                <Link to="/" onClick={closeMenu}>
                  <FaHome className="nav-icon"/>
                  Home
                </Link>
              </li>
              <li>
                <Link to="/about" onClick={closeMenu}>
                  <FaUsers className="nav-icon"/>
                  About
                </Link>
              </li>
              <li>
                <Link to="/veneciasStory" onClick={closeMenu}>
                  <FaBookOpen className="nav-icon"/>
                  Venecia's Story
                </Link>
              </li>
              <li>
                <Link to="/news" onClick={closeMenu}>
                  <FaNewspaper className="nav-icon"/>
                  News
                </Link>
              </li>
              <li>
                <Link to="/events" onClick={closeMenu}>
                  <FaCalendarAlt className="nav-icon"/>
                  Events
                </Link>
              </li>
              <li>
                <Link to="/volunteer" onClick={closeMenu}>
                  <FaHandsHelping className="nav-icon"/>
                  Volunteer
                </Link>
              </li>
              <li>
                {/* "Ways to Give" highlighted as a pink button */}
                <Link
                    to="/ways-to-give"
                    onClick={closeMenu}
                    className="donate-button"
                >
                  <FaHandHoldingHeart className="nav-icon"/>
                  Ways to Give
                </Link>
              </li>
              <li>
                <Link to="/contact" onClick={closeMenu}>
                  <MdContactPhone className="nav-icon"/>
                  Contact
                </Link>
              </li>
            </ul>
          </nav>
        </div>

        {/* Right: Social + Hamburger */}
        <div className="right-section">
          <div className={`social-block ${menuOpen ? 'open' : ''}`}>
            <span className="follow-us-text">Follow us on social media!</span>
            <div className="social-icons-row">
              <a
                  href="https://www.facebook.com/veneciasfoundation"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-link"
              >
                <FaFacebook className="social-icon" />
                <span className="social-text">Facebook</span>
              </a>
              <a
                  href="https://www.instagram.com/veneciasfoundation"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-link"
              >
                <FaInstagram className="social-icon" />
                <span className="social-text">Instagram</span>
              </a>
            </div>
          </div>

          <div className="hamburger" onClick={toggleMenu}>
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </div>
        </div>
      </header>
  );
}

export default Header;
