// src/components/VeneciasStory/VeneciasStory.jsx
import React from "react";
import "./VeneciasStory.css";

function VeneciasStory() {
  return (
      <main className="story-container" role="main" aria-labelledby="story-title">
        <h1 className="story-title" id="story-title">Venecia's Story</h1>

        {/* SECTION 1 */}
        <div className="story-section" aria-labelledby="section1-heading">
          <div className="story-text">
            <h2 id="section1-heading" className="visually-hidden">Section 1</h2>
            <p>
              Venecia Butler was a strong and determined woman who first faced breast cancer in 2006. She won her first battle, but the disease returned five years later. With more knowledge and even a sense of humor—she nicknamed the illness "CRAP"—she fought even harder and won again.
            </p>
          </div>
          <div className="story-image-container">
            <img
                src="/images/Photos_With_Venecia/venecia.jpg"
                alt="Venecia showing strength and inspiration"
                className="story-image"
            />
          </div>
        </div>

        {/* SECTION 2 */}
        <div className="story-section reverse" aria-labelledby="section2-heading">
          <div className="story-image-container">
            <img
                src="/images/Photos_With_Venecia/4.JPG"
                alt="Image depicting courage and support"
                className="story-image"
            />
          </div>
          <div className="story-text">
            <h2 id="section2-heading" className="visually-hidden">Section 2</h2>
            <p>
              In 2012 and 2013, Venecia was diagnosed with cancer two more times. With a positive attitude and the support of her family, friends, and close childhood pals (the YaYa’s), she kept fighting. She believed that her faith in Jesus Christ, her humor, and the love around her made all the difference.
            </p>
            <p>
              Venecia shared her journey in her book <em>I Have to Get Some Things Off My Chest</em>, published by Bookmark Publishers.
            </p>
          </div>
        </div>

        {/* SECTION 4 */}
        <div className="story-section reverse" aria-labelledby="section4-heading">
          <div className="story-image-container">
            <img
                src="/images/Photos_With_Venecia/speaking.jpg"
                alt="Image showing her tough fight"
                className="story-image"
            />
          </div>
          <div className="story-text">
            <h2 id="section4-heading" className="visually-hidden">Section 4</h2>
            <p>
              In August 2014, she faced her toughest fight when cancer returned for the fifth time and spread to her liver, spine, and brain. Even with a grim outlook, she continued to speak about her journey and inspire many until November 2014.
            </p>
          </div>
        </div>

        {/* SECTION 5: Full width */}
        <div className="story-section full-width" aria-labelledby="section5-heading">
          <div className="story-text">
            <h2 id="section5-heading" className="visually-hidden">Section 5</h2>
            <p>
              Venecia lived in Piedmont, Alabama with her husband Curtis and their two children, Nathan and Chelsea.
            </p>
          </div>
        </div>

        {/* SECTION 6: Highlighted "Her Journey" */}
        <div className="story-section highlight-section" aria-labelledby="her-journey-heading">
          <div className="story-text">
            <h2 id="her-journey-heading">Her Journey</h2>
            <p>
              In 2005, Venecia had a hysterectomy and started hormone therapy. In February 2006, she discovered a painful lump in her left breast that turned out to be cancer. After a mastectomy and rounds of chemotherapy and radiation, she began her battle.
            </p>
            <p>
              In 2011, a lump in her right breast led to a second mastectomy. Once cancer-free after treatment, she felt called to share her story. This led her to write her book and, in May 2013, to start Venecia’s Foundation to help others fighting cancer.
            </p>
          </div>
          <div className="story-image-container">
            <img
                src="/images/Photos_With_Venecia/foundation.jpg"
                alt="Image symbolizing hope and recovery"
                className="story-image"
            />
          </div>
        </div>

        {/* SECTION 7: Full width */}
        <div className="story-section full-width" aria-labelledby="section7-heading">
          <div className="story-text">
            <h2 id="section7-heading" className="visually-hidden">Section 7</h2>
            <p>
              She also faced a chest wall tumor in 2012 and small lung spots in 2013, both requiring further chemotherapy. In April 2013, she announced she was cancer-free for the fourth time. In August 2014, when cancer returned, she humorously called it her "Goliath" and continued speaking at events to offer hope.
            </p>
          </div>
        </div>

        {/* SECTION 8: Full width */}
        <div className="story-section full-width" aria-labelledby="section8-heading">
          <div className="story-text">
            <h2 id="section8-heading" className="visually-hidden">Section 8</h2>
            <p>
              Venecia’s caring nature inspired her to create Venecia’s Foundation, a nonprofit that helps cancer patients and their families. She dreamed of easing the burden of treatment with care packages, portable DVD players in chemo rooms, and gas cards.
            </p>
          </div>
        </div>

        {/* SECTION 9: Full width */}
        <div className="story-section full-width" aria-labelledby="section9-heading">
          <div className="story-text">
            <h2 id="section9-heading" className="visually-hidden">Section 9</h2>
            <p>
              Venecia passed away on April 23, 2015, leaving behind a legacy of strength, kindness, and a commitment to help others overcome cancer.
            </p>
          </div>
        </div>

        {/* QUOTE SECTION */}
        <div className="quote-section" aria-labelledby="quote-heading">
          <div className="quote-image-container">
            <img
                src="/images/Photos_With_Venecia/5.jpg"
                alt="Portrait of Venecia"
                className="quote-image"
            />
          </div>
          <div className="quote-text-container">
            <blockquote className="quote-text" id="quote-heading">
              “If you think about it, God used a little boy to kill a giant...
              <br />
              I want to get finished with what I was meant to do.”
            </blockquote>
          </div>
        </div>
      </main>
  );
}

export default VeneciasStory;
