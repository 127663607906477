// src/components/BagAnimation/BagAnimation.jsx
import React, { useRef, useEffect } from 'react';
import './BagAnimation.css';

function BagAnimation() {
  const containerRef = useRef(null);

  // When the final item (Kleenex) finishes its animation, add an extra class if needed.
  const handleFinalItemAnimationEnd = () => {
    if (containerRef.current) {
      containerRef.current.classList.add('bag-shift-up');
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (containerRef.current) {
              if (entry.isIntersecting) {
                containerRef.current.classList.add('start-animation');
              } else {
                containerRef.current.classList.remove('start-animation');
                containerRef.current.classList.remove('bag-shift-up');
              }
            }
          });
        },
        { threshold: 0.3 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
      observer.disconnect();
    };
  }, []);

  return (
      <div
          className="bag-animation-container"
          ref={containerRef}
          aria-hidden="true"
      >
        {/* Main Bag */}
        <img
            src="/images/Bag_Animation/Main_Bag.png"
            alt="Care Bag"
            className="bag-image"
        />

        {/* Animated Items */}
        <img
            src="/images/Bag_Animation/Blanket.png"
            alt="Blanket"
            className="item item-blanket"
        />
        <img
            src="/images/Bag_Animation/Big_Pillow.png"
            alt="Big Pillow"
            className="item item-big-pillow"
        />
        <img
            src="/images/Bag_Animation/Small_Pillow.png"
            alt="Small Pillow"
            className="item item-small-pillow"
        />
        <img
            src="/images/Bag_Animation/Word_Search.png"
            alt="Word Search"
            className="item item-word-search"
        />
        <img
            src="/images/Bag_Animation/Journal.png"
            alt="Journal"
            className="item item-journal"
        />
        <img
            src="/images/Bag_Animation/Lotion.png"
            alt="Lotion"
            className="item item-lotion"
        />
        <img
            src="/images/Bag_Animation/Mints.png"
            alt="Mints"
            className="item item-mints"
        />
        <img
            src="/images/Bag_Animation/Toothbrush.png"
            alt="Toothbrush"
            className="item item-toothbrush"
        />
        <img
            src="/images/Bag_Animation/Toothpaste.png"
            alt="Toothpaste"
            className="item item-toothpaste"
        />
        <img
            src="/images/Bag_Animation/Kleenex.png"
            alt="Kleenex"
            className="item item-kleenex"
            onAnimationEnd={handleFinalItemAnimationEnd}
        />
      </div>
  );
}

export default BagAnimation;
