// src/components/Volunteer/Volunteer.jsx
import React from 'react';
import './Volunteer.css';

function Volunteer() {
  return (
      <main className="volunteer-container" role="main" aria-labelledby="volunteer-heading">
        <h1 id="volunteer-heading" className="volunteer-title">Volunteer</h1>

        <div className="volunteer-content">
          {/* Image Section */}
          <div className="volunteer-image-container">
            <img
                src="/images/Care_Bags/Care_Bags33.jpg"
                alt="Volunteers in action supporting the foundation"
                className="volunteer-image"
            />
          </div>

          {/* Text and Form Section */}
          <div className="volunteer-text-container">
            <div className="volunteer-text">
              <p>
                Join our team of dedicated volunteers who make a real difference in the lives of cancer patients and their families. Your support—whether through hands-on assistance or behind-the-scenes work—helps us deliver care and hope to those in need.
              </p>
            </div>

            <form className="volunteer-form" action="/submit-volunteer" method="POST">
              <label htmlFor="volunteer-name" className="volunteer-label">Name *</label>
              <input id="volunteer-name" className="volunteer-input" type="text" name="name" required />

              <label htmlFor="volunteer-email" className="volunteer-label">Email *</label>
              <input id="volunteer-email" className="volunteer-input" type="email" name="email" required />

              <label htmlFor="volunteer-message" className="volunteer-label">Why do you want to volunteer?</label>
              <textarea id="volunteer-message" className="volunteer-textarea" name="message" rows="5" required></textarea>

              <button type="submit" className="volunteer-email-button">
                Submit
              </button>
            </form>
          </div>
        </div>
      </main>
  );
}

export default Volunteer;
