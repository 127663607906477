// src/components/Contact/Contact.jsx
import React, { useRef } from "react";
import "./Contact.css";
import { FaFacebook, FaInstagram } from "react-icons/fa";

function Contact() {
  const statusRef = useRef(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;
    const status = statusRef.current;
    if (!status) return; // Safeguard if ref is not available

    const data = new FormData(form);
    fetch(form.action, {
      method: form.method,
      body: data,
      headers: {
        Accept: "application/json",
      },
    })
        .then((response) => {
          if (response.ok) {
            status.innerHTML = "Thanks for your submission!";
            form.reset();
          } else {
            response.json().then((data) => {
              if (data && Object.hasOwn(data, "errors")) {
                status.innerHTML = data["errors"]
                    .map((error) => error["message"])
                    .join(", ");
              } else {
                status.innerHTML =
                    "Oops! There was a problem submitting your form";
              }
            });
          }
        })
        .catch(() => {
          status.innerHTML = "Oops! There was a problem submitting your form";
        });
  };

  return (
      <main className="contact-page" role="main">
        <h1 className="contact-heading">Contact</h1>

        <div className="contact-row">
          {/* LEFT COLUMN: Cartoon Image */}
          <div className="contact-left">
            <img
                src="/images/Photos_With_Venecia/1.jpg"
                alt="Cartoon illustration representing contact"
                className="contact-image"
            />
          </div>

          {/* RIGHT COLUMN: Info & Form */}
          <div className="contact-right">
            <p className="contact-intro">
              Would you like to help support Venecia's Foundation? Do you have a
              question, comment, or prayer request? We would love to hear from you.
            </p>

            <div className="contact-details">
              <p>
                <strong>EMAIL:</strong>{" "}
                <a href="mailto:veneciasfoundation@gmail.com">
                  veneciasfoundation@gmail.com
                </a>
              </p>
              <p>
                <strong>MAIL:</strong>
                <br />
                P.O. Box 572
                <br />
                Piedmont, Alabama 36272
              </p>
            </div>

            {/* SOCIAL ICONS */}
            <div className="contact-socials">
              <a
                  href="https://www.facebook.com/veneciasfoundation"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-link"
              >
                <FaFacebook className="social-icon" />
                <span className="social-text">Facebook</span>
              </a>
              <a
                  href="https://www.instagram.com/veneciasfoundation"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-link"
              >
                <FaInstagram className="social-icon" />
                <span className="social-text">Instagram</span>
              </a>
            </div>

            {/* FORMSPREE FORM */}
            <form
                id="my-form"
                action="https://formspree.io/f/xnnparlr"
                method="POST"
                onSubmit={handleSubmit}
                className="contact-form"
            >
              <label htmlFor="name">Name *</label>
              <input id="name" type="text" name="name" required />

              <label htmlFor="email">Email *</label>
              <input id="email" type="email" name="email" required />

              <label htmlFor="subject">Subject</label>
              <input id="subject" type="text" name="subject" />

              <label htmlFor="message">Message</label>
              <textarea id="message" name="message" rows="5" required />

              <button id="my-form-button" type="submit">
                Send
              </button>

              <p
                  id="my-form-status"
                  ref={statusRef}
                  role="status"
                  aria-live="polite"
              ></p>
            </form>
          </div>
        </div>
      </main>
  );
}

export default Contact;
