// src/components/Hero/Hero.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import './Hero.css';

function Hero() {
  return (
      <section className="hero-section">
        {/* Centered content wrapper with fade-in animation */}
        <div className="hero-wrapper fade-in">
          {/* Left Column: Headline, description, and CTA */}
          <div className="hero-content">
            <h1 className="hero-title">
              Supporting Cancer Patients &amp; Their Families
            </h1>
            <p className="hero-description">
              Venecia’s Foundation is a nonprofit dedicated to offering care, comfort, and hope to those fighting
              cancer—one visit at a time.
              <br />
              Our passionate volunteers carefully prepare care packages filled with warm blankets, essential items, and
              uplifting treats, ensuring every patient feels valued and supported throughout their journey.
            </p>
            <Link to="/about" className="hero-cta">
              Learn More
            </Link>
          </div>

          {/* Right Column: Large Circular-Fade Image */}
          <div className="hero-image-container">
            <img
                src="/images/Photos_With_Venecia/2.jpg"
                alt="Venecia hugging a patient"
                className="hero-image"
            />
          </div>
        </div>

        {/* Wave (absolute at bottom, spans full width) */}
        <div className="wave-container">
          <svg
              className="wave-svg"
              viewBox="0 0 1440 320"
              preserveAspectRatio="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                fill="#f3f4f5"
                fillOpacity="1"
                d="M0,64L60,64C120,64,240,64,360,80C480,96,600,128,720,149.3C840,171,960,181,1080,160C1200,139,1320,85,1380,58.7L1440,32L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
            />
          </svg>
        </div>
      </section>
  );
}

export default Hero;
