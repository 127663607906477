// src/components/SmallCollage/SmallCollage.jsx
import React, { useRef, useState, useEffect, useImperativeHandle } from 'react';
import './SmallCollage.css';

const SmallCollage = React.forwardRef((props, ref) => {
  const containerRef = useRef(null);
  const sliderBarRef = useRef(null);
  const sliderCircleRef = useRef(null);

  // Forward the container ref so parent components can control scrolling if needed
  useImperativeHandle(ref, () => containerRef.current);

  // Desktop slider dragging state
  const isDraggingSliderRef = useRef(false);
  const sliderStartXRef = useRef(0);
  const sliderStartLeftRef = useRef(0);

  // For mobile collage dragging (using native scrolling)
  const isDraggingCollageRef = useRef(false);
  const startXRef = useRef(0);
  const scrollLeftRef = useRef(0);

  const [autoScroll, setAutoScroll] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [loadedCount, setLoadedCount] = useState(0);
  const collageImages = Array.from({ length: 49 }, (_, i) => `/images/Collage/collage${i + 1}.jpg`);
  const totalImages = collageImages.length;
  const isLoading = loadedCount < totalImages;

  // Update mobile status on resize.
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      setAutoScroll(window.innerWidth >= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Auto-scroll effect (desktop only and when images are loaded).
  useEffect(() => {
    if (isMobile || isLoading) return;
    const container = containerRef.current;
    if (!container) return;
    let animationFrameId;
    function autoScrollStep() {
      if (autoScroll) {
        container.scrollLeft += 1; // Adjust scrolling speed as needed
        if (container.scrollLeft >= container.scrollWidth - container.clientWidth) {
          container.scrollLeft = 0;
        }
        updateSliderPosition();
      }
      animationFrameId = requestAnimationFrame(autoScrollStep);
    }
    autoScrollStep();
    return () => cancelAnimationFrame(animationFrameId);
  }, [autoScroll, isMobile, isLoading]);

  // Update slider circle position based on scroll progress.
  const updateSliderPosition = () => {
    if (!sliderBarRef.current || !containerRef.current) return;
    const barWidth = sliderBarRef.current.clientWidth;
    const maxScroll = containerRef.current.scrollWidth - containerRef.current.clientWidth;
    const progress = containerRef.current.scrollLeft / maxScroll;
    const circleWidth = sliderCircleRef.current.clientWidth;
    const leftPos = progress * (barWidth - circleWidth);
    sliderCircleRef.current.style.left = `${leftPos}px`;
  };

  // Desktop collage dragging handlers.
  const handleCollagePointerDown = (e) => {
    isDraggingCollageRef.current = true;
    setAutoScroll(false);
    containerRef.current.classList.add('dragging');
    containerRef.current.setPointerCapture(e.pointerId);
    startXRef.current = e.pageX - containerRef.current.offsetLeft;
    scrollLeftRef.current = containerRef.current.scrollLeft;
  };

  const handleCollagePointerMove = (e) => {
    if (!isDraggingCollageRef.current) return;
    e.preventDefault();
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = (x - startXRef.current) * 2; // Adjust sensitivity if needed
    containerRef.current.scrollLeft = scrollLeftRef.current - walk;
    updateSliderPosition();
  };

  const handleCollagePointerUp = (e) => {
    if (!isDraggingCollageRef.current) return;
    isDraggingCollageRef.current = false;
    containerRef.current.classList.remove('dragging');
    containerRef.current.releasePointerCapture(e.pointerId);
    setTimeout(() => setAutoScroll(true), 2000);
  };

  const handleCollagePointerLeave = (e) => {
    if (!isDraggingCollageRef.current) return;
    isDraggingCollageRef.current = false;
    containerRef.current.classList.remove('dragging');
  };

  // Desktop slider drag handlers.
  const handleSliderPointerDown = (e) => {
    isDraggingSliderRef.current = true;
    setAutoScroll(false);
    sliderCircleRef.current.classList.add('dragging');
    sliderStartXRef.current = e.pageX;
    sliderStartLeftRef.current = sliderCircleRef.current.offsetLeft;
    sliderCircleRef.current.setPointerCapture(e.pointerId);
    e.stopPropagation();
  };

  const handleSliderPointerMove = (e) => {
    if (!isDraggingSliderRef.current) return;
    e.preventDefault();
    const dx = e.pageX - sliderStartXRef.current;
    const sliderBarWidth = sliderBarRef.current.clientWidth;
    const circleWidth = sliderCircleRef.current.clientWidth;
    let newLeft = sliderStartLeftRef.current + dx;
    newLeft = Math.max(0, Math.min(newLeft, sliderBarWidth - circleWidth));
    sliderCircleRef.current.style.left = `${newLeft}px`;
    const maxScroll = containerRef.current.scrollWidth - containerRef.current.clientWidth;
    const progress = newLeft / (sliderBarWidth - circleWidth);
    containerRef.current.scrollLeft = progress * maxScroll;
  };

  const handleSliderPointerUp = (e) => {
    if (!isDraggingSliderRef.current) return;
    isDraggingSliderRef.current = false;
    sliderCircleRef.current.classList.remove('dragging');
    sliderCircleRef.current.releasePointerCapture(e.pointerId);
    setTimeout(() => setAutoScroll(true), 2000);
  };

  // Global pointerup for slider if pointer is released outside.
  useEffect(() => {
    const handleGlobalPointerUp = (e) => {
      if (isDraggingSliderRef.current) {
        isDraggingSliderRef.current = false;
        if (sliderCircleRef.current) {
          sliderCircleRef.current.classList.remove('dragging');
          sliderCircleRef.current.releasePointerCapture(e.pointerId);
        }
        setTimeout(() => setAutoScroll(true), 2000);
      }
    };
    window.addEventListener('pointerup', handleGlobalPointerUp);
    return () => window.removeEventListener('pointerup', handleGlobalPointerUp);
  }, []);

  // Image load handler.
  const handleImageLoad = () => {
    setLoadedCount(prev => prev + 1);
  };

  return (
      <div className="small-collage-container" role="region" aria-label="Image Collage">
        <div
            className="collage-wrapper"
            ref={containerRef}
            {...(!isMobile
                ? {
                  onPointerDown: handleCollagePointerDown,
                  onPointerMove: handleCollagePointerMove,
                  onPointerUp: handleCollagePointerUp,
                  onPointerLeave: handleCollagePointerLeave,
                }
                : {})}
        >
          {collageImages.map((src, index) => (
              <div key={index} className="collage-item">
                <img src={src} alt={`Collage image ${index + 1}`} onLoad={handleImageLoad} />
              </div>
          ))}
        </div>

        {!isMobile && (
            <div className="slider-bar" ref={sliderBarRef}>
              <div
                  className="slider-circle"
                  ref={sliderCircleRef}
                  onPointerDown={handleSliderPointerDown}
                  onPointerMove={handleSliderPointerMove}
                  onPointerUp={handleSliderPointerUp}
              ></div>
            </div>
        )}

        {isLoading && (
            <div className="loading-overlay">
              <div className="loading-logo">
                <img src="/images/vFoundationLogo.png" alt="Loading Logo" />
              </div>
              <div className="loading-text">Loading images...</div>
            </div>
        )}

        {isMobile && (
            <div className="mobile-hint">
              Scroll left and right to view images
            </div>
        )}
      </div>
  );
});

export default SmallCollage;
