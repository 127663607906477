// src/components/About/About.jsx
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import SmallCollage from '../SmallCollage/SmallCollage';
import './About.css';

function About() {
  // ---------------------------
  // 1) BAG STATS DATA & STATES
  // ---------------------------
  const statsData = [
    { year: 2019, delivered: 308 },
    { year: 2020, delivered: 204 },
    { year: 2021, delivered: 300 },
    { year: 2022, delivered: 285 },
    { year: 2023, delivered: 306 },
    { year: 2024, delivered: 317 },
  ];
  const BAG_TOTAL = 1720;

  // States for bag stats animation
  const [hasAnimated, setHasAnimated] = useState(false);
  const [animatedValues, setAnimatedValues] = useState(statsData.map(() => 0));
  const [animatedTotal, setAnimatedTotal] = useState(0);

  // ----------------------------
  // 2) GAS CARD DATA & STATES (unused animation now)
  // ----------------------------
  // The gas gauge animation is removed; we now display a static image instead.
  const GAS_TOTAL = 71510;
  const [animatedGas, setAnimatedGas] = useState(0);
  const [needleAngle, setNeedleAngle] = useState(-90);

  // -------------------------
  // 3) INTERSECTION OBSERVER (with reset)
  // -------------------------
  const impactRef = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setHasAnimated(true);
          observer.unobserve(entry.target);
        }
      });
    }, { threshold: 0.3 });

    if (impactRef.current) {
      observer.observe(impactRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  // ----------------------------------
  // 4) ANIMATE BAG STATS (0 .. delivered)
  // ----------------------------------
  useEffect(() => {
    if (!hasAnimated) return;
    const duration = 4000;
    const startTime = performance.now();

    function step(currentTime) {
      let progress = (currentTime - startTime) / duration;
      if (progress > 1) progress = 1;
      const newValues = statsData.map(item => Math.floor(item.delivered * progress));
      setAnimatedValues(newValues);
      setAnimatedTotal(Math.floor(BAG_TOTAL * progress));
      if (progress < 1) {
        requestAnimationFrame(step);
      }
    }
    requestAnimationFrame(step);
  }, [hasAnimated]);

  // (Optional: Remove gas gauge animation useEffect as it's no longer displayed)
  /*
  useEffect(() => {
    if (!hasAnimated) return;
    const duration = 4000;
    const start = performance.now();
    function animateGas(currentTime) {
      let progress = (currentTime - start) / duration;
      if (progress > 1) progress = 1;
      setAnimatedGas(Math.floor(GAS_TOTAL * progress));
      setNeedleAngle(-90 + progress * 180);
      if (progress < 1) {
        requestAnimationFrame(animateGas);
      }
    }
    requestAnimationFrame(animateGas);
  }, [hasAnimated]);
  */

  // ---------------------------------------
  // 5) READ .bar-container WIDTH VIA REF
  // ---------------------------------------
  const barContainerRefs = useRef([]);
  function getBarContainerWidth(index) {
    const ref = barContainerRefs.current[index];
    if (!ref) return 260;
    return parseFloat(window.getComputedStyle(ref).width) || 260;
  }

  // Constants for bag stat positioning
  const ICON_WIDTH = 70;
  const TEXT_WIDTH = 10;
  const TEXT_OFFSET = 4;

  // ---------------------------------------
  // 6) SCROLL TO IMPACT SECTION IF HASH EXISTS
  // ---------------------------------------
  useEffect(() => {
    if (window.location.hash === "#impact") {
      const impactElement = document.getElementById("impact");
      if (impactElement) {
        impactElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  return (
      <main className="about-page" role="main">
        {/* ====================== INTRODUCTION SECTION ====================== */}
        <section className="about-foundation" aria-labelledby="about-foundation-heading">
          <div className="container about-row">
            <div className="about-text">
              <h2 id="about-foundation-heading">About Venecia's Foundation</h2>
              <p>
                Venecia’s Foundation empowers cancer patients and their families by offering practical support and compassionate care. Born from the belief that small acts of kindness—like assembling care bags with essentials—can bring relief during life’s toughest battles, our organization has grown into a community-driven nonprofit dedicated to meeting real, day-to-day needs.
              </p>
              <p>
                Supported by volunteers, donors, and partners, we continuously expand our reach through fundraisers and charity events, ensuring every patient receives the support they need. Every act of kindness contributes to a larger tapestry of hope and care.
              </p>
            </div>
            <div className="about-image">
              <img src="/images/Care_Bags/Care_Bags5.jpg" alt="Foundation Volunteers & Patients" />
            </div>
          </div>
        </section>

        {/* ====================== FOUNDER SECTION ====================== */}
        <section className="founder" aria-labelledby="founder-heading">
          <div className="container founder-container">
            <h2 id="founder-heading" className="founder-title">Venecia Butler</h2>
            <div className="founder-image">
              <img src="/images/Photos_With_Venecia/venecia.jpg" alt="Portrait of Venecia Butler" />
            </div>
            <div className="founder-text">
              <p>
                While undergoing chemotherapy and radiation, Venecia witnessed firsthand the struggles faced by others around her. Drawing from her experiences, she began assembling care bags filled with essentials like lotion, blankets, and snacks to bring comfort to those in need. Inspired by the impact of these small acts of kindness, she transformed her efforts into a nonprofit that organizes fundraisers and charity events. With the support of dedicated volunteers, countless care bags are now packed and distributed to hospitals, offering hope and practical support.
              </p>
              <Link to="/veneciasStory" className="button">
                Discover Venecia's Story
              </Link>
            </div>
          </div>
        </section>

        {/* ====================== IMPACT SECTION ====================== */}
        <section id="impact" className="impact" ref={impactRef} aria-labelledby="impact-heading">
          <div className="container impact-grid">
            <div className="impact-info">
              <h2 id="impact-heading">Our Impact</h2>
              <p>
                Venecia’s Foundation has delivered thousands of care bags to support patients and their families, while also distributing gas cards to ease transportation costs. Our work makes a tangible difference by providing essential resources and hope during challenging times.
              </p>
            </div>
            <div className="impact-graph">
              <div className="bag-stats-container">
                <h3>Care Bags Delivered</h3>
                {statsData.map((item, index) => {
                  const deliveredVal = animatedValues[index];
                  const maxDelivered = Math.max(...statsData.map(s => s.delivered));
                  const fraction = maxDelivered > 0 ? deliveredVal / maxDelivered : 0;
                  const containerWidth = getBarContainerWidth(index);
                  let bagLeftRaw = fraction * containerWidth;
                  bagLeftRaw = Math.min(bagLeftRaw, containerWidth - ICON_WIDTH);
                  const barWidth = bagLeftRaw;
                  let textLeft = bagLeftRaw + ICON_WIDTH + TEXT_OFFSET;
                  textLeft = Math.min(textLeft, containerWidth - TEXT_WIDTH);
                  return (
                      <div key={item.year} className="impact-bar-row">
                        <div className="impact-year">{item.year}</div>
                        <div className="bar-container" ref={el => (barContainerRefs.current[index] = el)}>
                          <div className="bar-fill" style={{ width: `${barWidth}px` }} />
                          <img
                              className="carebag-icon"
                              src="/images/Bag_Animation/Main_Bag.png"
                              alt="Care Bag being packed with things to help cancer patients"
                              style={{ left: `${bagLeftRaw}px` }}
                          />
                          <div className="impact-count" style={{ left: `${textLeft}px` }}>
                            {deliveredVal}
                          </div>
                        </div>
                      </div>
                  );
                })}
                {hasAnimated && (
                    <div className="impact-total">
                      Total: {animatedTotal} bags <br />delivered since 2019.
                    </div>
                )}
              </div>
              <div className="gas-gauge-container">
                <h3>Gas Cards Distributed</h3>
                <p className="gauge-explanation">
                  Over $71,510 in gas cards.
                </p>
                <img src="/images/Gas_Cards/GasCards2.jpg" alt="Gas Cards Distributed" className="gas-cards-image" />
              </div>
            </div>
          </div>
        </section>

        {/* ====================== GALLERY SECTION ====================== */}
        <section className="gallery-section" aria-labelledby="gallery-heading">
          <div className="container">
            <h2 id="gallery-heading" className="gallery-title">Gallery</h2>
            <p className="gallery-intro">
              Here are some photos that show the heart of Venecia’s Foundation in action. They capture our hospital visits, community events, and the moments that bring comfort to people fighting cancer.
            </p>
            <div className="small-collage-wrapper">
              <SmallCollage />
            </div>
          </div>
        </section>

        {/* ====================== TEAM SECTION ====================== */}
        <section className="team" aria-labelledby="team-heading">
          <div className="container team-container">
            <h2 id="team-heading">Meet Our Team</h2>
            <div className="team-content">
              <div className="team-text">
                <p>
                  Our foundation board is made up of dedicated individuals who organize fundraisers and care bag events, ensuring every donation turns into impactful support. Contact us for more information or to request support.
                </p>
                <Link to="/contact" className="button">Contact Us</Link>
              </div>
              <div className="team-image">
                <img src="/images/Team/TheTeam.JPG" alt="Our Team" />
              </div>
            </div>
          </div>
        </section>

        {/* ====================== CALL TO ACTION SECTION ====================== */}
        <section className="cta" aria-labelledby="cta-heading">
          <div className="container">
            <h2 id="cta-heading">Join Our Mission</h2>
            <p>
              Whether you choose to volunteer, donate, or share our message, every bit of support makes a difference. Together, we can bring practical help and hope to those who need it most.
            </p>
            <Link to="/volunteer" className="button">
              Get Involved
            </Link>
          </div>
        </section>

        {/* ====================== DONOR SECTION ====================== */}
        <section className="donor-section" aria-labelledby="donor-heading">
          <div className="container">
            <h2 id="donor-heading" className="donor-headline">Thanks to Our Supporters</h2>
            <div className="donor-content">
              <p>
                Every donation, whether individual or organizational, makes a significant impact on our mission. Your generous support helps us deliver care bags and provide essential resources to those in need.
              </p>
              <Link to="/ways-to-give" className="button">Donate</Link>
            </div>
            <div className="donor-images">
              {/* Donor cards */}
              <div className="donor-card">
                <img src="/images/Donors/Donor1.JPG" alt="An image of Rick and Bubba supporting Venecia's Foundation with a check." />
                <div className="donor-caption">
                  What a great day! Thank you to everyone who supported this awesome donation.
                </div>
              </div>
              <div className="donor-card">
                <img src="/images/Donors/Donor2.JPG" alt="An image of Piedmont High school supporting Venecia's Foundation with a check." />
                <div className="donor-caption">
                  We are so grateful for the support that keeps the dream alive.
                </div>
              </div>
              <div className="donor-card">
                <img src="/images/Donors/Donor3.jpg" alt="Another image of Piedmont High school supporting Venecia's Foundation with a check." />
                <div className="donor-caption">
                  Thank you Piedmont High School for supporting our cause every year!
                </div>
              </div>
              <div className="donor-card">
                <img src="/images/Donors/Donor4.jpg" alt="Another image of Family Savings Credit Union supporting Venecia's Foundation with a check." />
                <div className="donor-caption">
                  Your generous donation makes a huge impact. Thank you for supporting this amazing cause!
                </div>
              </div>
              <div className="donor-card">
                <img src="/images/Donors/Donor5.jpg" alt="Another image of Piedmont High school supporting Venecia's Foundation with a check." />
                <div className="donor-caption">
                  Thank you to our community for your donation from the Annual Powderpuff Football game!
                </div>
              </div>
              <div className="donor-card">
                <img src="/images/Donors/Donor6.jpg" alt="Another image of Family Savings Credit Union supporting Venecia's Foundation with a check." />
                <div className="donor-caption">
                  In October of 2019, Family Savings locations raised $800 for our cause. We are so grateful.
                </div>
              </div>
              <div className="donor-card">
                <img src="/images/Donors/Donor7.jpg" alt="An image of Family Credit Union supporting Venecia's Foundation with a check." />
                <div className="donor-caption">
                  Thank you to Family Credit Union for raising $2,000 for our foundation in 2018!
                </div>
              </div>
              <div className="donor-card">
                <img src="/images/Donors/Donor8.jpg" alt="Another image of Piedmont High school supporting Venecia's Foundation with a check." />
                <div className="donor-caption">
                  Thank you Piedmont High School for donating $800 from the Powderpuff football game!
                </div>
              </div>
              <div className="donor-card">
                <img src="/images/Donors/Donor9.jpg" alt="Another image of Family Savings Credit Union supporting Venecia's Foundation with a check." />
                <div className="donor-caption">
                  $1,000 was donated from Family Savings Credit Union. Thank you to all who supported us in October 2017.
                </div>
              </div>
              <div className="donor-card">
                <img src="/images/Donors/Donor10.JPG" alt="An image of Aaron Studdard supporting Venecia's Foundation with a check." />
                <div className="donor-caption">
                  This is what paying it forward is all about. Aaron Studdard received a bag and then helped sell 600 shirts to support our cause. Please pray for Aaron.
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
  );
}

export default About;
