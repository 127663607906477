// src/components/Mission/Mission.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import BagAnimation from '../BagAnimation/BagAnimation';
import './Mission.css';

function Mission() {
  return (
      <section className="mission-section fade-in" aria-labelledby="mission-heading">
        <div className="mission-container">
          <h2 id="mission-heading" className="mission-heading">Our Mission</h2>

          <div className="mission-content">
            <div className="mission-text">
              <p className="mission-paragraph">
                At Venecia’s Foundation, we bring hope and comfort to cancer patients and their families by delivering care packages, heartfelt visits, and even toys for children in treatment. Our support lifts spirits, eases burdens, and reminds everyone that no one fights alone.
              </p>
              <br />
              <p className="mission-paragraph">
                Beyond material aid, our mission is to foster a community of compassion through personal connections, fundraising events, and outreach programs. By sharing stories of resilience and expanding our partnerships with hospitals and community organizations, we ensure every patient receives the support they need to keep fighting.
              </p>
              <br />
              <p className="mission-paragraph">
                <Link to="/volunteer" style={{ color: '#ED2D92', textDecoration: 'none', fontWeight: '700' }}>
                  Join us
                </Link> in our endeavor to spread love, hope, and practical assistance to those battling cancer. Together, we can make a meaningful difference, continuing Venecia's legacy of resilience, compassion, and unwavering dedication to supporting others in their time of need.
              </p>
            </div>

            <div className="bag-column">
              <BagAnimation />
            </div>
          </div>

          {/* Pillars Section */}
          <div className="mission-pillars">
            <div className="pillar-card">
              <img
                  src="/images/Photos_With_Venecia/3.jpg"
                  alt="Volunteer delivering a care package"
                  className="pillar-image"
              />
              <h3 className="pillar-title">Compassion</h3>
              <p className="pillar-text">
                We put together special care packages and offer real support to remind people going through cancer that they're not alone.
              </p>
              <Link to="/about#impact" className="pillar-cta">Our Impact</Link>
            </div>

            <div className="pillar-card">
              <img
                  src="/images/Patients/Braylon3.jpg"
                  alt="Volunteer delivering toys to a child"
                  className="pillar-image"
              />
              <h3 className="pillar-title">Support</h3>
              <p className="pillar-text">
                We connect patients and families with resources and emotional support throughout their journey.
              </p>
              <Link to="/ways-to-give" className="pillar-cta">Ways to Give</Link>
            </div>

            <div className="pillar-card">
              <img
                  src="/images/Care_Bags/Care_Bag4.jpg"
                  alt="Workers at a hospital volunteering for the foundation"
                  className="pillar-image"
              />
              <h3 className="pillar-title">Community</h3>
              <p className="pillar-text">
                By uniting local volunteers, hospitals, and donors, we foster a network of care—from fundraising events to heartfelt in-person visits.
              </p>
              <Link to="/volunteer" className="pillar-cta">Volunteer</Link>
            </div>
          </div>
        </div>

        <div className="wave-container reversed-wave-bottom" aria-hidden="true">
          <svg className="wave-svg" viewBox="0 0 1440 320" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fill="black"
                fillOpacity="1"
                d="M0,64L60,64C120,64,240,64,360,80C480,96,600,128,720,149.3C840,171,960,181,1080,160C1200,139,1320,85,1380,58.7L1440,32L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
            />
          </svg>
        </div>
      </section>
  );
}

export default Mission;
