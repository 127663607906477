// src/components/CharityEvents/CharityEvents.jsx
import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './CharityEvents.css';

function CharityEvents() {
  const videoRef = useRef(null);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              if (videoRef.current) {
                videoRef.current.play();
              }
            } else {
              if (videoRef.current) {
                videoRef.current.pause();
                videoRef.current.currentTime = 0;
              }
            }
          });
        },
        { threshold: 0.5 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
      <section
          className="charity-events-section"
          ref={sectionRef}
          aria-labelledby="charity-events-heading"
      >
        <div className="charity-events-container">
          {/* 1) Heading & Paragraphs */}
          <div className="charity-events-text">
            <h2 id="charity-events-heading" className="charity-events-heading">
              Charity Events
            </h2>
            <p className="charity-events-description">
              We host different charity fundraisers throughout the year to bring our community
              together in making a real difference. Whether it's the fun of a 5K run or the joy
              of a community get-together, each event helps us raise the money we need to support
              those in need.
            </p>
            <p className="charity-events-description">
              Join us at our next event and experience firsthand how every stride and smile
              contributes to a brighter future!
            </p>
          </div>

          {/* 2) Video, right below the text */}
          <div className="charity-events-video">
            <video
                ref={videoRef}
                src="videos/5kRun.mp4"
                muted
                loop
                playsInline
                className="charity-video"
                aria-label="Charity event video showing community participation in a 5K run"
            >
              Your browser does not support the video tag.
            </video>
          </div>

          {/* 3) CTA button, directly below the video */}
          <div className="charity-events-cta">
            <Link to="/events" className="charity-cta-button">
              View More
            </Link>
          </div>
        </div>

        {/* Wave Container */}
        <div className="wave-container">
          <svg
              className="wave-svg"
              viewBox="0 0 1440 320"
              preserveAspectRatio="none"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
          >
            <path
                fill="#f3f4f5"
                fillOpacity="1"
                d="M0,64L60,64C120,64,240,64,360,80C480,96,600,128,720,149.3C840,171,960,181,1080,160C1200,139,1320,85,1380,58.7L1440,32L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
            />
          </svg>
        </div>
      </section>
  );
}

export default CharityEvents;
