// src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Hero from './components/Hero/Hero';
import Mission from './components/MissionImpact/Mission';
import CharityEvents from './components/CharityEvents/CharityEvents';
import Testimonials from './components/Testimonials/Testimonials';
import About from './components/About/About';
import VeneciasStory from './components/VeneciasStory/VeneciasStory';
import News from './components/News/News';
import Events from './components/Events/Events';
import ScrollToTop from "./components/ScrollToTop";

// 1) Import your Volunteer component
import Volunteer from './components/Volunteer/Volunteer';

// 2) Import your WaysToGive component
import WaysToGive from './components/WaysToGive/WaysToGive';

// 3) Import your Contact component
import Contact from './components/Contact/Contact';

import './App.css';

function App() {
  return (
      <Router>
        <ScrollToTop />
        <Header />
        <Routes>
          {/* HOME PAGE */}
          <Route
              path="/"
              element={
                <>
                  <Hero />
                  <Mission />
                  <CharityEvents />
                  <Testimonials />
                </>
              }
          />

          {/* OTHER ROUTES */}
          <Route path="/about" element={<About />} />
          <Route path="/news" element={<News />} />
          <Route path="/veneciasStory" element={<VeneciasStory />} />
          <Route path="/events" element={<Events />} />

          {/* VOLUNTEER */}
          <Route path="/volunteer" element={<Volunteer />} />

          {/* WAYS TO GIVE */}
          <Route path="/ways-to-give" element={<WaysToGive />} />

          {/* CONTACT */}
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </Router>
  );
}

export default App;
