import React from "react";
import { Helmet } from "react-helmet";
import "./News.css";

function News() {
  return (
      <div className="news-container" id="top">
        {/* Open Graph Meta Tags */}
        <Helmet>
          <meta property="og:title" content="Venecia's Foundation - Inspiring Hope" />
          <meta
              property="og:description"
              content="Stay up to date with the latest news and events from our foundation."
          />
          <meta
              property="og:image"
              content="https://yourwebsite.com/path/to/desired-image.jpg"
          />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="og:url" content="https://yourwebsite.com/news" />
        </Helmet>

        {/* Newsletter Signup */}
        <div className="newsletter-signup">
          {/* Mailchimp Embed Code */}
          <div id="mc_embed_shell">
            <form
                action="https://veneciasfoundation.us13.list-manage.com/subscribe/post?u=2e9d28d7dd19c85e6ed96372d&amp;id=17b40d05b7&amp;f_id=00197be9f0"
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                className="validate"
                target="_blank"
            >
              <div id="mc_embed_signup_scroll">
                <h2>Subscribe to Our Monthly Newsletter!</h2>
                <div className="indicates-required">
                  Stay up to date with the latest news and events from our foundation!
                </div>
                <div className="mc-field-group">
                  <label htmlFor="mce-EMAIL">
                    Email Address <span className="asterisk">* </span>
                  </label>
                  <input
                      type="email"
                      name="EMAIL"
                      className="required email"
                      id="mce-EMAIL"
                      required
                      defaultValue=""
                  />
                </div>
                <div id="mce-responses" className="clear">
                  <div
                      className="response"
                      id="mce-error-response"
                      style={{ display: "none" }}
                  ></div>
                  <div
                      className="response"
                      id="mce-success-response"
                      style={{ display: "none" }}
                  ></div>
                </div>
                <div aria-hidden="true" style={{ position: "absolute", left: "-5000px" }}>
                  <input
                      type="text"
                      name="b_2e9d28d7dd19c85e6ed96372d_17b40d05b7"
                      tabIndex="-1"
                      value=""
                  />
                </div>
                <div className="clear">
                  <input
                      type="submit"
                      name="subscribe"
                      id="mc-embedded-subscribe"
                      className="mc-button"
                      value="Subscribe"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>

        {/* February 2025 Section */}
        <h2 className="news-headline">February 2025</h2>

        {/* Article: February 24th */}
        <div className="news-article">
          <img
              src="/images/News/February2025.jpg"
              alt="Care bags in Rome"
              className="news-image"
          />
          <div className="news-content">
            <h3>February 24th</h3>
            <p>
              We delivered care bags and spread even more hope throughout Rome.
              Everyone was excited to see our team arrive, and the positive impact
              was immediate. We appreciate the community’s continued support in
              helping us make a difference.
            </p>
            {/* Social Share Buttons */}
            <div className="social-share">
              <span>Share:</span>
              <a
                  href="https://twitter.com/intent/tweet?text=Check out Venecia's Foundation! They delivered cancer care bags to a hospital in Rome, Georgia!"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                Twitter
              </a>
              <a
                  href="https://www.facebook.com/sharer/sharer.php?u=https://veneciasfoundation.org/news"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                Facebook
              </a>
            </div>
          </div>
        </div>

        {/* Article: February 22nd */}
        <div className="news-article">
          <img
              src="/images/News/February2025-2.jpg"
              alt="Board meeting"
              className="news-image"
          />
          <div className="news-content">
            <h3>February 22nd</h3>
            <p>
              Our team met for our annual first-of-the-year board meeting this
              morning. Last year was a record year for Venecia’s Foundation.
              Because of your support, and the efforts of our amazing volunteers,
              that wouldn’t have been possible. This will be the 10th year without
              our fearless leader. In her memory, we hope to make this year even
              better – arming more warriors for this battle. Thank you for
              honoring what we do by your donations.
            </p>
            {/* Social Share Buttons */}
            <div className="social-share">
              <span>Share:</span>
              <a
                  href="https://twitter.com/intent/tweet?text=Check out Venecia's Foundation! They had an annual board meeting for 2025 and noticed that 2024 was their record year!"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                Twitter
              </a>
              <a
                  href="https://www.facebook.com/sharer/sharer.php?u=https://veneciasfoundation.org/news"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                Facebook
              </a>
            </div>
          </div>
        </div>

        {/* January 2025 Section */}
        <h2 className="news-headline">January 2025</h2>

        {/* Article: January 29th */}
        <div className="news-article">
          <img
              src="/images/News/January2025.jpg"
              alt="Alabama Cancer Care delivery"
              className="news-image"
          />
          <div className="news-content">
            <h3>January 29th</h3>
            <p>
              We delivered bags to Alabama Cancer Care of Anniston, brightening
              the day for patients and staff. Our volunteers took the time to
              answer questions and share words of encouragement. Thank you to
              everyone who helped make this possible.
            </p>
            {/* Social Share Buttons */}
            <div className="social-share">
              <span>Share:</span>
              <a
                  href="https://twitter.com/intent/tweet?text=Check out Venecia's Foundation! They delivered bags to Alabama Cancer Care!"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                Twitter
              </a>
              <a
                  href="https://www.facebook.com/sharer/sharer.php?u=https://veneciasfoundation.org/news"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                Facebook
              </a>
            </div>
          </div>
        </div>

        {/* Article: January 4th */}
        <div className="news-article">
          <img
              src="/images/News/Janurary2025-2.jpg"
              alt="Annual polar plunge"
              className="news-image"
          />
          <div className="news-content">
            <h3>January 4th</h3>
            <p>
              Our 11th annual Polar Plunge was a success – and a chilly one at
              that! A total of $557.77 was raised today, and we want to thank
              those who braved the cold water for such a great cause.
            </p>
            {/* Social Share Buttons */}
            <div className="social-share">
              <span>Share:</span>
              <a
                  href="https://twitter.com/intent/tweet?text=Check out Venecia's Foundation! They had a Polar Plunge in January 2025 that raised $557.77!"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                Twitter
              </a>
              <a
                  href="https://www.facebook.com/sharer/sharer.php?u=https://veneciasfoundation.org/news"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                Facebook
              </a>
            </div>
          </div>
        </div>

        {/* Support Our Mission CTA */}
        <div className="support-cta">
          <h2>Support Our Mission</h2>
          <p>
            Help us continue Venecia’s vision of delivering hope to cancer patients.
            Your donation or volunteer time can make a huge difference.
          </p>
          <div className="cta-buttons">
            <a href="/ways-to-give" className="donate-button">
              Donate
            </a>
            <a href="/volunteer" className="volunteer-button">
              Volunteer
            </a>
          </div>
        </div>

        {/* Back to Top */}
        <div className="back-to-top">
          <a href="#top">Back to Top</a>
        </div>
      </div>
  );
}

export default News;
