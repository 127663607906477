// src/components/Testimonials/Testimonials.jsx
import React, { useState } from 'react';
import './Testimonials.css';

const shortTestimonials = [
  {
    quote: "I was blessed to receive a bag from a very dear family to help comfort me during this difficult journey. So thankful for what you do!",
    author: "Brie Smith"
  },
  {
    quote: "It was such a big help when I received my bag during my first round of chemotherapy!",
    author: "Sarah Fredette"
  },
  {
    quote: "This is an amazing support system. It means so much to so many.",
    author: "Katlin Adele Pruitt"
  }
];

const longTestimonials = [
  {
    quote: "My oncology nurse gave me a bag as I was going to be admitted in the hospital at Tanner Carrollton because of an infection and high fever during chemo. It was absolutely the best gift ever! A small pillow that was so needed and the softest blanket ever! I love them both. They supported me during that 9-day hospital stay, again at Emory for surgery and the days afterward. I still use both almost daily. Thank you so much for the love and care you put in the bags—they mean so much!!!",
    author: "Jenny Christmas"
  },
  {
    quote: "I had never heard of Venetia's Foundation before. Today, during my second chemo treatment, I received a gift package to help me relax. When I woke, the nurse told me it was from a kind young lady named Mrs. Flynt, whom I had seen at the cancer center but never really spoken to. She made my day. I never knew I needed someone to show me they cared, but today I did.",
    author: "Meriel Booker"
  },
  {
    quote: "This is wonderful work to support. I never imagined that my father-in-law and my 52-year-old strong, healthy husband would both be struck with very serious cancers within two months of each other. The bags you gave us are so thoughtful and incredibly useful during chemo treatments. It means a lot to know that people care enough to spread comfort to patients and caregivers. We are fortunate to have this organization in our area!",
    author: "LaVonda Burns Hardin"
  },
  {
    quote: "Thank you for all you do. I received a goodie bag today. I am currently undergoing chemo treatments at Anniston Oncology for a low platelet count. They asked if I had received one; I said no because I wasn't there for cancer, but they said all chemo patients get the bags. I love everything in it. Thanks again for everything.",
    author: "Carla Ballew"
  },
  {
    quote: "I was so excited to receive the bag of goodies. I am currently undergoing chemo at Anniston Oncology for peritoneal cancer, also known as ovarian cancer. When the nurses asked if I had already received one, I said no, and I was filled with childlike excitement—so much stuff that I needed and use. You are such a blessing. It pains my heart to hear your story, but you have been a blessing to me and many others. I was also told that my cancer is very likely to recur. I have surgery next, followed by more chemo, so I will keep you in my prayers, and I hope you keep me in yours. The foundation is awesome.",
    author: "Teresa Wilkerson"
  }
];

function Testimonials() {
  // mode: "short" shows the grid of short testimonials; "long" shows one long testimonial.
  const [mode, setMode] = useState("short");
  const [currentLong, setCurrentLong] = useState(0);
  const [fade, setFade] = useState(false);
  const fadeDuration = 500; // milliseconds

  const handleNext = () => {
    setFade(true);
    setTimeout(() => {
      if (mode === "short") {
        // Switch to long mode and show the first long testimonial.
        setMode("long");
        setCurrentLong(0);
      } else {
        // In long mode, if not at the end, go to next long testimonial.
        if (currentLong < longTestimonials.length - 1) {
          setCurrentLong((prev) => prev + 1);
        }
      }
      setFade(false);
    }, fadeDuration);
  };

  const handlePrev = () => {
    if (mode === "long") {
      setFade(true);
      setTimeout(() => {
        if (currentLong === 0) {
          // Return to short mode.
          setMode("short");
        } else {
          setCurrentLong((prev) => prev - 1);
        }
        setFade(false);
      }, fadeDuration);
    }
  };

  return (
      <section className="testimonials-section" aria-label="Testimonials">
        <div className="testimonials-container">
          <h2 className="testimonials-heading">Stories That Inspire</h2>
          <div className={`testimonials-content ${fade ? "fade" : ""}`} aria-live="polite">
            {mode === "short" ? (
                <div className="short-testimonials-grid">
                  {shortTestimonials.map((t, index) => (
                      <div key={index} className="testimonial-card short">
                        <p className="testimonial-quote">"{t.quote}"</p>
                        <p className="testimonial-author">- {t.author}</p>
                      </div>
                  ))}
                </div>
            ) : (
                <div className="long-testimonial">
                  <div className="testimonial-card long">
                    <p className="testimonial-quote">"{longTestimonials[currentLong].quote}"</p>
                    <p className="testimonial-author">- {longTestimonials[currentLong].author}</p>
                  </div>
                </div>
            )}
          </div>
          <div className="testimonials-controls">
            <button
                onClick={handlePrev}
                className="testimonial-button"
                disabled={mode === "short"}
            >
              Previous
            </button>
            <button
                onClick={handleNext}
                className="testimonial-button"
                disabled={mode === "long" && currentLong === longTestimonials.length - 1}
            >
              Next
            </button>
          </div>
        </div>
      </section>
  );
}

export default Testimonials;
