// src/components/Events/Events.jsx
import React from "react";
import { Link } from "react-router-dom";
import "./Events.css";

function Events() {
  return (
      <main>
        {/* Full-Width Hero Section */}
        <section className="events-hero-section" aria-labelledby="events-hero-title">
          <div className="events-hero-wrapper fade-in">
            {/* Left Column: Headline & Description */}
            <div className="events-hero-content">
              <h1 id="events-hero-title" className="events-hero-title">
                Join Our Events
              </h1>
              <p className="events-hero-description">
                Our events bring people together for fun and a shared purpose.
                Each gathering is a chance to raise vital funds for Venecia’s Foundation, helping us
                support cancer patients and their families. Your participation makes a
                real difference!
              </p>
            </div>

            {/* Right Column: Large Image */}
            <div className="events-hero-image-container">
              <img
                  src="/images/Events/Polar_Plunge2.jpg"
                  alt="A vibrant image showcasing a community event at Venecia’s Foundation"
                  className="events-hero-image"
              />
            </div>
          </div>

        </section>

        {/* Main Content Container (below the hero wave) */}
        <div className="events-container">
          <div className="events-layout">
            {/* LEFT COLUMN: Previous Events */}
            <div className="previous-events" aria-labelledby="previous-events-heading">
              <h2 id="previous-events-heading" className="events-title">
                Previous Events
              </h2>

              {/* Annual 5K Run */}
              <div className="event-card">
                <h3>Annual 5K Run (2024)</h3>
                <div className="event-video">
                  <video width="100%" controls aria-label="Video of the Annual 5K Run">
                    <source src="/videos/5KRun-1.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <p>
                  Our annual 5K run was a huge success, raising $52,420.52 for Venecia’s Foundation!
                </p>
              </div>

              {/* Annual Polar Plunge */}
              <div className="event-card polar-plunge">
                <img
                    src="/images/Events/Polar_Plunge.jpg"
                    alt="Image showing participants at the Annual Polar Plunge"
                    className="event-image"
                />
                <div className="polar-plunge-text">
                  <h3>Annual Polar Plunge (2024)</h3>
                  <p>
                    Our 11th annual Polar Plunge was a chilly success, raising $557.77 for our cause.
                  </p>
                </div>
              </div>
            </div>

            {/* RIGHT COLUMN: Upcoming Events */}
            <div className="upcoming-events" aria-labelledby="upcoming-events-heading">
              <h2 id="upcoming-events-heading" className="events-title">
                Upcoming Events
              </h2>

              <div className="event-item">
                <h3>June (10 AM)</h3>
                <p>Terrapin Float @ No Worries Kayaks</p>
              </div>

              <div className="event-item">
                <h3>October 4 (6 PM)</h3>
                <p>Crap Run @ Venecia’s Foundation</p>
              </div>

              <div className="event-item">
                <h3>Nov 21 – Dec 31</h3>
                <p>Year-End Giving Campaign (Online: FB &amp; Website)</p>
              </div>

              <div className="event-item">
                <h3>December (5 PM)</h3>
                <p>Christmas House Tour</p>
              </div>

              <div className="event-item">
                <h3>January 3, 2026 (10 AM)</h3>
                <p>Polar Plunge @ City of Piedmont Pool</p>
              </div>
            </div>
          </div>
        </div>
      </main>
  );
}

export default Events;
