// src/components/Footer/Footer.jsx
import React from 'react';
import './Footer.css';

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
      <footer className="site-footer">
        <div className="footer-info">
          <p>© {currentYear} Venecia’s Foundation. All rights reserved.</p>
          <p>
            We are a 501(c)(3) nonprofit organization dedicated to supporting families
            through cancer journeys.
          </p>
        </div>
        <div className="footer-social">
          {/* Replace with real social links if available */}
          <a
              href="https://www.facebook.com/veneciasfoundation"
              target="_blank"
              rel="noreferrer"
          >
            Facebook
          </a>
          <a
              href="https://www.instagram.com/veneciasfoundation"
              target="_blank"
              rel="noreferrer"
          >
            Instagram
          </a>
        </div>
      </footer>
  );
}

export default Footer;
