// src/components/WaysToGive/WaysToGive.jsx
import React from "react";
import "./WaysToGive.css";
import DonateByMailPDF from "../../PDF/DonateByMail.pdf";

function WaysToGive() {
  return (
      <main className="ways-to-give" role="main" aria-labelledby="ways-to-give-heading">
        {/* HERO SECTION */}
        <section className="wtg-hero-section" aria-labelledby="wtg-hero-heading">
          <div className="wtg-hero-inner">
            <div className="wtg-hero-text">
              <h1 id="wtg-hero-heading" className="wtg-hero-heading">Ways to Give</h1>
              <p className="wtg-hero-paragraph">
                At Venecia's Foundation, we are grateful for your support. Whether you contribute your time, talent, or financial resources, your generosity is a precious gift that helps those struggling with cancer. By donating, hosting a fundraiser, or sponsoring an event, you can help us make a real difference in the community! <br/><br/>
              </p>
            </div>
            <div className="wtg-hero-image">
              <img
                  src="images/Care_Bags/Care_Bags32.jpg"
                  alt="Collage of care bags and community events representing ways to give"
                  className="wtg-hero-img"
              />
            </div>
          </div>
          <div className="wtg-hero-wave" aria-hidden="true">
            <svg viewBox="0 0 500 150" preserveAspectRatio="none">
              <path
                  d="M-0.00,49.98 C149.99,150.00 349.99,-50.00 500.00,49.98 L500.00,150.00 L-0.00,150.00 Z"
                  style={{ stroke: "none", fill: "#F3F4F5" }}
              />
            </svg>
          </div>
        </section>

        {/* MAIN CONTENT CONTAINER */}
        <section className="wtg-content-container">
          {/* DONATE SECTION */}
          <section className="wtg-section" aria-labelledby="donate-heading">
            <h2 id="donate-heading">Donate</h2>
            <p>
              Thank you for supporting Venecia's Foundation. You have the option to donate via mail or online. If your donation is in honor or memory of a special person, please let us know.
            </p>
            <ul>
              <li>
                To make a donation quickly and securely, you can use PayPal — a convenient system that allows you to donate to any charity, anywhere, anytime.
              </li>
              <li>
                If you prefer to send a check by mail:
                <ul className="wtg-nested-list">
                  <li>Make check payable to Venecia's Foundation</li>
                  <li>Include a note referencing your donation</li>
                  <li>
                    Send your donation to:
                    <br />
                    Venecia's Foundation
                    <br />
                    PO Box 352
                    <br />
                    Piedmont, Alabama 36272
                  </li>
                </ul>
              </li>
            </ul>
            <a
                className="wtg-paypal-open-button"
                href="https://www.paypal.com/donate/?hosted_button_id=NA4N8YFMXWMRL"
                target="_blank"
                rel="noopener noreferrer"
            >
              Donate With PayPal
            </a>
            <a
                className="wtg-mail-open-button"
                href={DonateByMailPDF}
                target="_blank"
                rel="noopener noreferrer"
            >
              Donate By Mail
            </a>
          </section>

          {/* FUNDRAISE SECTION */}
          <section className="wtg-section" aria-labelledby="fundraise-heading">
            <h2 id="fundraise-heading">Fundraise</h2>
            <p>
              Start fundraising today and lift a cancer patient’s spirit. You can help us provide more comfort and make a difference by organizing a fundraiser around an activity you love. By giving your time, you can bring support and comfort to cancer patients.
            </p>
            <ul>
              <li>
                <strong>Host a Local Care Bag Drive:</strong> Gather essential items for Venecia's Foundation or sponsor a packing party with your friends.
              </li>
              <li>
                <strong>Celebrate a Birthday, Anniversary, or Holiday:</strong> Set up a Facebook Fundraiser.
              </li>
              <li>
                <strong>Rally your office, church, or school:</strong> Host your own fundraiser and challenge your colleagues or pay tribute to a loved one.
              </li>
            </ul>
            <p className="wtg-fundraiser-extra">
              Additionally, you can create a fundraiser directly on Facebook. This is a great way to quickly mobilize your network and make an impact.
            </p>
            <a
                href="https://www.facebook.com/veneciasfoundation/fundraisers"
                className="wtg-facebook-button"
                target="_blank"
                rel="noopener noreferrer"
            >
              Start a Facebook Fundraiser
            </a>
          </section>
        </section>
      </main>
  );
}

export default WaysToGive;
